/* @import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900@display-swap'); */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* 思源黑体 */
@font-face {
  font-family: "SOURCEHANSANSCN-BOLD";
  src: url("../../assets/images/SOURCEHANSANSCN-BOLD.OTF");
}
/* 优设黑标 */
@font-face {
  font-family: "YouSheBiaoTiHei";
  src: url("../../assets/images/YouSheBiaoTiHei-2.ttf");
}
/* 斜体 */
@font-face {
  font-family: "italic";
  src: url("../../assets/images/italic.TTF");
}

body {
  background-color: #fff;
  min-height: 100vh;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; /* 1920px改成100%*/
  display: flex;
  justify-content: space-between;
  transition: all 0.6s;
  padding: 33px 86px;
  z-index: 999;
}

header .logo {
  width: 190px;
  height: 34px;
  background-image: url(../../assets/images/logo_f.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media screen and (max-width: 768px) {
  header .logo {
    width: 40px;
    height: 10px;
    background-image: url(../../assets/images/logo_f.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-top: 8px;
  }
}
.header_nav {
  display: flex;
}
.header_nav_s {
  display: flex;
  font-family: "SOURCEHANSANSCN-BOLD";
}
header .topnav {
  display: flex;
}
.topnav_itm {
  width: 170px;
  height: 100%;
  text-align: center;
  color: #fff;
  letter-spacing: 2px;
  font-weight: bold;
  border-radius: 2px;
  margin-right: 20px;
  cursor: default;
}
.topnav_itm:hover .subnav {
  display: block;
  background-color: rgba(66, 63, 63, 0.5);
}
.dialog {
  display: none;
}
.topnav_itm:hover .dialog {
  width: 300px;
  height: 300px;
  display: block;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .mainnav {
    padding-top: 5px;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.mainnav {
  padding-top: 5px;
  font-size: 20px;
}
.subnav {
  display: none;
  padding: 10px 0;
}
.subnav_itm {
  font-size: 16px;
  padding: 5px 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}
.subnav_itm:hover {
  color: #ccc;
}
@media screen and (max-width: 768px) {
  .language {
    color: #fff;
    letter-spacing: 2px;
    margin-left: 80px;
    font-size: 8px !important;
  }
}
.language {
  padding-top: 7px;
  color: #fff;
  letter-spacing: 2px;
  font-weight: bold;
  margin-left: 80px;
  transition: 0.6s;
  cursor: pointer;
}
.langIsActive {
  color: #c1c1c1;
}

header.sticky {
  background-color: #fff;
}
.sticky .logo {
  width: 190px;
  height: 34px;
  background-image: url(../../assets/images/logo_b.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media screen and (max-width: 768px) {
  .sticky .logo {
    width: 40px;
    height: 10px;
    background-image: url(../../assets/images/logo_b.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-top: 8px;
  }
}
header.sticky .logo,
header.sticky .topnav .topnav_itm,
header.sticky .language {
  color: #000;
}
header.sticky .topnav_itm:hover .subnav {
  display: block;
  background-color: rgba(255, 255, 255);
  box-shadow: 0 4px 8px 0 rgb(55 14 14 / 8%);
}

@media screen and (max-width: 768px) {
  .swiper-slide img {
    width: 100% !important;
    height: 170px !important;
  }
}

.information {
  margin: 14px 0 28px 456px;
}
.information_s {
  margin: 14px 0 28px 456px;
  font-family: "SOURCEHANSANSCN-BOLD";
}

.information_text {
  width: 198px;
  height: 70px;
  margin-bottom: 2px;
}

.information_top {
  display: flex;
  align-items: center;
}

.information_left {
  width: 714px;
  height: 428px;
  margin-right: 10px;
  overflow: hidden;
}
.information_left img {
  width: 714px;
  height: 428px;
  transition: all 0.6s;
}

.information_left img:hover {
  transform: scale(1.2);
}

.information_right {
  position: relative;
  width: 243px;
  height: 428px;
  overflow: hidden;
  transition: all 0.6s;
}
.information_right img {
  width: 243px;
  height: 428px;
  transition: all 0.6s;
}
.information_right:hover img {
  transform: scale(1.2);
}
.information_right_t {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 243px;
  height: 137px;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  background-color: #0000ff;
  opacity: 0.48;
}
.information_right_b {
  width: 243px;
  height: 137px;
}

.information_center {
  margin: 25px 0;
}
.information_center_tit {
  width: 966px;
  line-height: 1.6em;
  font-size: 20px;
  font-weight: bold;
  border-bottom: solid 1px #c1272d;
  margin-bottom: 7px;
}
@media screen and (max-width: 768px) {
  .information_center_tit {
    line-height: 1.6em;
    font-size: 10px;
    font-weight: bold;
    border-bottom: solid 1px #c1272d;
    margin-bottom: 10px;
  }
  .article_item {
    font-size: 16px;
    font-weight: 500;
    height: 10px;
    line-height: 10px;
  }
}
.article_item {
  font-size: 16px;
  font-weight: 500;
  line-height: 2em;
}

.information_bottom {
  display: flex;
  align-items: center;
}
.information_bottom_y {
  display: flex;
  align-items: center;
  font-family: "YouSheBiaoTiHei";
}
.information_bottom_left {
  position: relative;
  width: 486px;
  height: 230px;
  line-height: 230px;
  text-align: center;
  font-size: 35px;
  letter-spacing: 3px;
  color: #fff;
  background-image: url(../../assets/images/information_four.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-right: 10px;
}
.information_bottom_text {
  position: absolute;
  top: 0;
  left: 0;
  width: 486px;
  text-align: center;
  z-index: 99;
}
.information_bottom_left .bgc {
  width: 0;
  transition: all 0.6s;
}
.information_bottom_left:hover .bgc {
  position: absolute;
  top: 0;
  left: 0;
  width: 486px;
  height: 230px;
  background-color: #0000ff;
  opacity: 0.48;
}
.information_bottom_right {
  position: relative;
  width: 486px;
  height: 230px;
  line-height: 230px;
  text-align: center;
  font-size: 35px;
  color: #fff;
  background-image: url(../../assets/images/information_five.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.information_bottom_right .bgc {
  width: 0;
  transition: all 0.6s;
}
.information_bottom_right:hover .bgc {
  position: absolute;
  top: 0;
  left: 0;
  width: 486px;
  height: 230px;
  background-color: #0000ff;
  opacity: 0.48;
}

.zoology {
  display: flex;
  width: 100%; /* 1920px改成100%*/
  height: 842px;
  background-color: #1b1464;
}
.zoology_y {
  display: flex;
  width: 100%; /* 1920px改成100%*/
  height: 842px;
  background-color: #1b1464;
  font-family: "YouSheBiaoTiHei";
}
.zoology_classify {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 960px;
}
.classify_item {
  display: flex;
  align-items: center;
  height: 150px;
  padding-left: 149px;
  transition: all 1s;
}
.classify_item_default {
  display: flex;
  align-items: center;
  height: 150px;
  padding-left: 248px;
  transition: all 1s;
}
.classify_item:hover {
  padding-left: 248px;
}
.zoology_img {
  display: none;
  width: 759px;
  height: 747px;
  transition: all 3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.zoology_img_black {
  position: relative;
  display: block;
  width: 759px;
  height: 747px;
  animation: fadeIn 3s;
}
.annulus {
  width: 50px;
  height: 50px;
  opacity: 1;
  border: 8px solid #00e7fd;
  border-radius: 50%;
  margin-right: 30px;
  display: none;
}
.classify_item_default:hover .annulus {
  display: block;
}
.classify_item_default .annulus_default {
  width: 50px;
  height: 50px;
  opacity: 1;
  border: 8px solid #00e7fd;
  border-radius: 50%;
  margin-right: 30px;
  display: block;
}
.classify_item_text {
  font-size: 40px;
  color: #fff;
}
.zoology_img_black {
  position: relative;
  width: 50%;
  height: 100%;
  animation: fadeIn 3s;
}
.zoology_img_black img {
  position: absolute;
  bottom: calc(50% - 374px);
  left: calc(50% - 380px);
  width: 759px;
  height: 747px;
}
.zoology_img_tit {
  position: absolute;
  bottom: calc(50% - 374px);
  left: calc(50% - 380px);
  width: 759px;
}
.zoology_img_tit .text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  height: 209px;
  line-height: 209px;
  color: #fff;
  font-size: 30px;
  z-index: 99;
}
.zoology_img_black .bgc {
  width: 100%;
  height: 209px;
  background: #00e7fd;
  opacity: 0.29;
}

.game_img {
  width: 100%; /* 1920px改成100%*/
  height: 844px;
  margin: 87px 0 95px 0;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.footer_s {
  width: 985px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 467px;
  font-family: "SOURCEHANSANSCN-BOLD";
}
.footer_tit {
  width: 985px;
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  color: #595757;
  line-height: 2em;
  border-bottom: 3px solid #595757;
}
.footer_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}
.footer_icon_item {
  position: relative;
  margin: 30px;
}
.footer_icon_item_img {
  width: 67px;
  height: 58px;
}
.footer_icon_item_qrcode {
  display: none;
}
.footer_icon_item:hover .footer_icon_item_qrcode {
  display: block;
  position: absolute;
  top: -110px;
  right: -75px;
  width: 100px;
  height: 100px;
}
.footer_nav {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0 50px 0;
}
.footer_nav_items {
  color: #595757;
  font-size: 25px;
  font-weight: bold;
  margin-right: 42px;
}
.copyright {
  width: 100vw;
  height: 20px;
  margin-bottom: 30px;
  font-size: 19px;
  display: flex;
  justify-content: center;
  align-items: center;

  .records{
    display: flex;
    align-items: flex-start;
  }
}

/* m端样式 */
.m_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 92px 112px;
  background-color: #fff;
}
.m_logo {
  width: 528px;
  height: 80px;
}
.m_nav_icon {
  width: 120px;
  height: 80px;
}
.m_main_h {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 500px 0 900px 0;
  height: calc(100vh - 266px);
  background-image: url(../../assets/images/m_image_bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;

}
.m_mc_tit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 100px;
  font-weight: bold;
  line-height: 1.6em;
}
.m_upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.m_upload_logo {
  width: 400px;
  height: 400px;
  border-radius: 67px;
}
.m_upload_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 800px;
  height: 200px;
  line-height: 200px;
  text-align: center;
  font-size: 60px;
  font-weight: bold;
  color: #182987;
  border-radius: 100px;
  background-color: #fff;
  margin-top: 200px;
}
.m_upload_icon {
  width: 40px;
  height: 40px;
  border-top: 6px solid #182987;
  border-right: 6px solid #182987;
  transform: rotate(45deg);
  margin-left: 40px;
}
.m_nav {
  width: 100%;
  position: fixed;
  top: 266px;
  left: 0;
  background-color: #fff;
  z-index: 99999;
}
.m_nav_item {
  width: 100%;
  height: 200px;
  line-height: 200px;
  color: #9d9d9d;
  background-color: #222222;
  padding-left: 120px;
}
.m_nav_item_active {
  width: 100%;
  height: 200px;
  line-height: 200px;
  color: #fff;
  background-color: #000;
  padding-left: 120px;
}

.layer {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000;
  z-index: 99;
  opacity: 0.8;
}

.layer .image_guide {
  margin: 10%;
  display: block;
  width: 80vw;
  z-index: 999;
}
