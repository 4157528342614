.m_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 92px 112px;
    background-color: #fff;
}
.m_logo {
    width: 528px;
    height: 80px;
}
.m_nav_icon {
    width: 120px;
    height: 80px;
}
.m_main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 500px 0;
    height: calc(100vh - 266px);
    background-image: url(../../assets/images/m_image_bg.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.m_mc_tit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 100px;
    font-weight: bold;
    line-height: 1.6em;
}
.m_upload {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.m_upload_logo {
    width: 400px;
    height: 400px;
    border-radius: 67px;
}
.m_upload_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 800px;
    height: 200px;
    line-height: 200px;
    text-align: center;
    font-size: 60px;
    font-weight: bold;
    color: #182987;
    border-radius: 100px;
    background-color: #fff;
    margin-top: 200px;
}
.m_upload_icon {
    width: 40px;
    height: 40px;
    border-top: 6px solid #182987;
    border-right: 6px solid #182987;
    transform: rotate(45deg);
    margin-left: 40px;
}
.m_nav {
    width: 100%;
    position: fixed;
    top: 266px;
    left: 0;
    background-color: #fff;
    z-index: 99999;
}
.m_nav_item {
    width: 100%;
    height: 200px;
    line-height: 200px;
    color: #9D9D9D;
    background-color: #222222;
    padding-left: 120px;
}
.m_nav_item_active {
    width: 100%;
    height: 200px;
    line-height: 200px;
    color: #fff;
    background-color: #000;
    padding-left: 120px;
}
.content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 100px 0;
    background-image: url(../../assets/images/m_image_bg.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.content_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 60px;
    margin: 200px 80px;
    background-color: #27478561;
    color: #fff;
    border-radius: 40px;
}
.upload_img {
    width: 600px;
    height: 600px;
    margin-top: 80px;
}
.footer {
    width: 90vw !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #23211F;
    color: #fff;
    padding: 80px 0;
    margin-top: 80px;
    border-radius: 20px;
    margin:  0 auto;
}
.about_user {
    font-size: 80px;
}
.line {
    width: 80%;
    height: 2px;
    background-color: #ccc;
    margin: 80px 0;
}
.icon_list {
    display: flex;
    align-items: center;
}
.icon_list_img {
    width: 200px;
    height: 200px;
    margin-right: 80px;
}
.icon_list_img:nth-last-child(1) {
    width: 200px;
    height: 200px;
    margin-right: 0;
}
.footer_nav {
    display: flex;
    align-items: center;
}
.footer_nav_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 70px;
    margin-right: 60px;
}
.footer_des {
    text-align: center;
    font-size: 24px;
    padding: 0 100px;
}