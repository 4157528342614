body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.content{
  width:100%;
  height: 26.042vw;
  display:flex;
}
.content .left{
  width:10.417vw;
  height: 26.042vw;
  background: #eeee;
}
.content .right{
  flex:1 1;
  height: 26.042vw;
  border:1px solid #000;
}

.App {
  /* text-align: center; */
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(0.521vw + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.title{
  height: 2.292vw;
  line-height: 2.292vw;
  text-align: center;
  background: #000;
  color:#fff;
}

.title a{
  color: #fff;
  padding:0px 1.042vw;
}

/* @import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900@display-swap'); */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* 思源黑体 */
@font-face {
  font-family: "SOURCEHANSANSCN-BOLD";
  src: url(/static/media/SOURCEHANSANSCN-BOLD.8e4c4a88.OTF);
}
/* 优设黑标 */
@font-face {
  font-family: "YouSheBiaoTiHei";
  src: url(/static/media/YouSheBiaoTiHei-2.1726685c.ttf);
}
/* 斜体 */
@font-face {
  font-family: "italic";
  src: url(/static/media/italic.1db3789e.TTF);
}

body {
  background-color: #fff;
  min-height: 100vh;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; /* 1920px改成100%*/
  display: flex;
  justify-content: space-between;
  transition: all 0.6s;
  padding: 1.719vw 4.479vw;
  z-index: 999;
}

header .logo {
  width: 9.896vw;
  height: 1.771vw;
  background-image: url(/static/media/logo_f.bfdba315.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media screen and (max-width: 768px) {
  header .logo {
    width: 40px;
    height: 10px;
    background-image: url(/static/media/logo_f.bfdba315.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-top: 8px;
  }
}
.header_nav {
  display: flex;
}
.header_nav_s {
  display: flex;
  font-family: "SOURCEHANSANSCN-BOLD";
}
header .topnav {
  display: flex;
}
.topnav_itm {
  width: 8.854vw;
  height: 100%;
  text-align: center;
  color: #fff;
  letter-spacing: 0.104vw;
  font-weight: bold;
  border-radius: 0.104vw;
  margin-right: 1.042vw;
  cursor: default;
}
.topnav_itm:hover .subnav {
  display: block;
  background-color: rgba(66, 63, 63, 0.5);
}
.dialog {
  display: none;
}
.topnav_itm:hover .dialog {
  width: 15.625vw;
  height: 15.625vw;
  display: block;
  background-color: #fff;
  border-radius: 1.042vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .mainnav {
    padding-top: 5px;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.mainnav {
  padding-top: 0.26vw;
  font-size: 1.042vw;
}
.subnav {
  display: none;
  padding: 0.521vw 0;
}
.subnav_itm {
  font-size: 0.833vw;
  padding: 0.26vw 0.781vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}
.subnav_itm:hover {
  color: #ccc;
}
@media screen and (max-width: 768px) {
  .language {
    color: #fff;
    letter-spacing: 2px;
    margin-left: 80px;
    font-size: 8px !important;
  }
}
.language {
  padding-top: 0.365vw;
  color: #fff;
  letter-spacing: 0.104vw;
  font-weight: bold;
  margin-left: 4.167vw;
  transition: 0.6s;
  cursor: pointer;
}
.langIsActive {
  color: #c1c1c1;
}

header.sticky {
  background-color: #fff;
}
.sticky .logo {
  width: 9.896vw;
  height: 1.771vw;
  background-image: url(/static/media/logo_b.bfdba315.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media screen and (max-width: 768px) {
  .sticky .logo {
    width: 40px;
    height: 10px;
    background-image: url(/static/media/logo_b.bfdba315.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-top: 8px;
  }
}
header.sticky .logo,
header.sticky .topnav .topnav_itm,
header.sticky .language {
  color: #000;
}
header.sticky .topnav_itm:hover .subnav {
  display: block;
  background-color: rgba(255, 255, 255);
  box-shadow: 0 0.208vw 0.417vw 0 rgb(55 14 14 / 8%);
}

@media screen and (max-width: 768px) {
  .swiper-slide img {
    width: 100% !important;
    height: 170px !important;
  }
}

.information {
  margin: 0.729vw 0 1.458vw 23.75vw;
}
.information_s {
  margin: 0.729vw 0 1.458vw 23.75vw;
  font-family: "SOURCEHANSANSCN-BOLD";
}

.information_text {
  width: 10.313vw;
  height: 3.646vw;
  margin-bottom: 0.104vw;
}

.information_top {
  display: flex;
  align-items: center;
}

.information_left {
  width: 37.188vw;
  height: 22.292vw;
  margin-right: 0.521vw;
  overflow: hidden;
}
.information_left img {
  width: 37.188vw;
  height: 22.292vw;
  transition: all 0.6s;
}

.information_left img:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.information_right {
  position: relative;
  width: 12.656vw;
  height: 22.292vw;
  overflow: hidden;
  transition: all 0.6s;
}
.information_right img {
  width: 12.656vw;
  height: 22.292vw;
  transition: all 0.6s;
}
.information_right:hover img {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
.information_right_t {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 12.656vw;
  height: 7.135vw;
  color: #fff;
  font-size: 1.042vw;
  font-weight: bold;
  background-color: #0000ff;
  opacity: 0.48;
}
.information_right_b {
  width: 12.656vw;
  height: 7.135vw;
}

.information_center {
  margin: 1.302vw 0;
}
.information_center_tit {
  width: 50.313vw;
  line-height: 1.6em;
  font-size: 1.042vw;
  font-weight: bold;
  border-bottom: solid 1px #c1272d;
  margin-bottom: 0.365vw;
}
@media screen and (max-width: 768px) {
  .information_center_tit {
    line-height: 1.6em;
    font-size: 10px;
    font-weight: bold;
    border-bottom: solid 1px #c1272d;
    margin-bottom: 10px;
  }
  .article_item {
    font-size: 16px;
    font-weight: 500;
    height: 10px;
    line-height: 10px;
  }
}
.article_item {
  font-size: 0.833vw;
  font-weight: 500;
  line-height: 2em;
}

.information_bottom {
  display: flex;
  align-items: center;
}
.information_bottom_y {
  display: flex;
  align-items: center;
  font-family: "YouSheBiaoTiHei";
}
.information_bottom_left {
  position: relative;
  width: 25.313vw;
  height: 11.979vw;
  line-height: 11.979vw;
  text-align: center;
  font-size: 1.823vw;
  letter-spacing: 0.156vw;
  color: #fff;
  background-image: url(/static/media/information_four.7df92c7b.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-right: 0.521vw;
}
.information_bottom_text {
  position: absolute;
  top: 0;
  left: 0;
  width: 25.313vw;
  text-align: center;
  z-index: 99;
}
.information_bottom_left .bgc {
  width: 0;
  transition: all 0.6s;
}
.information_bottom_left:hover .bgc {
  position: absolute;
  top: 0;
  left: 0;
  width: 25.313vw;
  height: 11.979vw;
  background-color: #0000ff;
  opacity: 0.48;
}
.information_bottom_right {
  position: relative;
  width: 25.313vw;
  height: 11.979vw;
  line-height: 11.979vw;
  text-align: center;
  font-size: 1.823vw;
  color: #fff;
  background-image: url(/static/media/information_five.8054f63a.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.information_bottom_right .bgc {
  width: 0;
  transition: all 0.6s;
}
.information_bottom_right:hover .bgc {
  position: absolute;
  top: 0;
  left: 0;
  width: 25.313vw;
  height: 11.979vw;
  background-color: #0000ff;
  opacity: 0.48;
}

.zoology {
  display: flex;
  width: 100%; /* 1920px改成100%*/
  height: 43.854vw;
  background-color: #1b1464;
}
.zoology_y {
  display: flex;
  width: 100%; /* 1920px改成100%*/
  height: 43.854vw;
  background-color: #1b1464;
  font-family: "YouSheBiaoTiHei";
}
.zoology_classify {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50vw;
}
.classify_item {
  display: flex;
  align-items: center;
  height: 7.813vw;
  padding-left: 7.76vw;
  transition: all 1s;
}
.classify_item_default {
  display: flex;
  align-items: center;
  height: 7.813vw;
  padding-left: 12.917vw;
  transition: all 1s;
}
.classify_item:hover {
  padding-left: 12.917vw;
}
.zoology_img {
  display: none;
  width: 39.531vw;
  height: 38.906vw;
  transition: all 3s;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.zoology_img_black {
  position: relative;
  display: block;
  width: 39.531vw;
  height: 38.906vw;
  -webkit-animation: fadeIn 3s;
          animation: fadeIn 3s;
}
.annulus {
  width: 2.604vw;
  height: 2.604vw;
  opacity: 1;
  border: 0.417vw solid #00e7fd;
  border-radius: 50%;
  margin-right: 1.563vw;
  display: none;
}
.classify_item_default:hover .annulus {
  display: block;
}
.classify_item_default .annulus_default {
  width: 2.604vw;
  height: 2.604vw;
  opacity: 1;
  border: 0.417vw solid #00e7fd;
  border-radius: 50%;
  margin-right: 1.563vw;
  display: block;
}
.classify_item_text {
  font-size: 2.083vw;
  color: #fff;
}
.zoology_img_black {
  position: relative;
  width: 50%;
  height: 100%;
  -webkit-animation: fadeIn 3s;
          animation: fadeIn 3s;
}
.zoology_img_black img {
  position: absolute;
  bottom: calc(50% - 19.479vw);
  left: calc(50% - 19.792vw);
  width: 39.531vw;
  height: 38.906vw;
}
.zoology_img_tit {
  position: absolute;
  bottom: calc(50% - 19.479vw);
  left: calc(50% - 19.792vw);
  width: 39.531vw;
}
.zoology_img_tit .text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  height: 10.885vw;
  line-height: 10.885vw;
  color: #fff;
  font-size: 1.563vw;
  z-index: 99;
}
.zoology_img_black .bgc {
  width: 100%;
  height: 10.885vw;
  background: #00e7fd;
  opacity: 0.29;
}

.game_img {
  width: 100%; /* 1920px改成100%*/
  height: 43.958vw;
  margin: 4.531vw 0 4.948vw 0;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.footer_s {
  width: 51.302vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 24.323vw;
  font-family: "SOURCEHANSANSCN-BOLD";
}
.footer_tit {
  width: 51.302vw;
  text-align: center;
  font-size: 2.083vw;
  font-weight: bold;
  color: #595757;
  line-height: 2em;
  border-bottom: 0.156vw solid #595757;
}
.footer_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.26vw;
}
.footer_icon_item {
  position: relative;
  margin: 1.563vw;
}
.footer_icon_item_img {
  width: 3.49vw;
  height: 3.021vw;
}
.footer_icon_item_qrcode {
  display: none;
}
.footer_icon_item:hover .footer_icon_item_qrcode {
  display: block;
  position: absolute;
  top: -5.729vw;
  right: -3.906vw;
  width: 5.208vw;
  height: 5.208vw;
}
.footer_nav {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2.604vw 0 2.604vw 0;
}
.footer_nav_items {
  color: #595757;
  font-size: 1.302vw;
  font-weight: bold;
  margin-right: 2.188vw;
}
.copyright {
  width: 100vw;
  height: 1.042vw;
  margin-bottom: 1.563vw;
  font-size: 0.99vw;
  display: flex;
  justify-content: center;
  align-items: center;

  .records{
    display: flex;
    align-items: flex-start;
  }
}

/* m端样式 */
.m_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4.792vw 5.833vw;
  background-color: #fff;
}
.m_logo {
  width: 27.5vw;
  height: 4.167vw;
}
.m_nav_icon {
  width: 6.25vw;
  height: 4.167vw;
}
.m_main_h {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 26.042vw 0 46.875vw 0;
  height: calc(100vh - 13.854vw);
  background-image: url(/static/media/m_image_bg.08edb8f4.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;

}
.m_mc_tit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 5.208vw;
  font-weight: bold;
  line-height: 1.6em;
}
.m_upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.m_upload_logo {
  width: 20.833vw;
  height: 20.833vw;
  border-radius: 3.49vw;
}
.m_upload_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 41.667vw;
  height: 10.417vw;
  line-height: 10.417vw;
  text-align: center;
  font-size: 3.125vw;
  font-weight: bold;
  color: #182987;
  border-radius: 5.208vw;
  background-color: #fff;
  margin-top: 10.417vw;
}
.m_upload_icon {
  width: 2.083vw;
  height: 2.083vw;
  border-top: 0.313vw solid #182987;
  border-right: 0.313vw solid #182987;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  margin-left: 2.083vw;
}
.m_nav {
  width: 100%;
  position: fixed;
  top: 13.854vw;
  left: 0;
  background-color: #fff;
  z-index: 99999;
}
.m_nav_item {
  width: 100%;
  height: 10.417vw;
  line-height: 10.417vw;
  color: #9d9d9d;
  background-color: #222222;
  padding-left: 6.25vw;
}
.m_nav_item_active {
  width: 100%;
  height: 10.417vw;
  line-height: 10.417vw;
  color: #fff;
  background-color: #000;
  padding-left: 6.25vw;
}

.layer {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000;
  z-index: 99;
  opacity: 0.8;
}

.layer .image_guide {
  margin: 10%;
  display: block;
  width: 80vw;
  z-index: 999;
}

.m_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4.792vw 5.833vw;
    background-color: #fff;
}
.m_logo {
    width: 27.5vw;
    height: 4.167vw;
}
.m_nav_icon {
    width: 6.25vw;
    height: 4.167vw;
}
.m_main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 26.042vw 0;
    height: calc(100vh - 13.854vw);
    background-image: url(/static/media/m_image_bg.08edb8f4.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.m_mc_tit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 5.208vw;
    font-weight: bold;
    line-height: 1.6em;
}
.m_upload {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.m_upload_logo {
    width: 20.833vw;
    height: 20.833vw;
    border-radius: 3.49vw;
}
.m_upload_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 41.667vw;
    height: 10.417vw;
    line-height: 10.417vw;
    text-align: center;
    font-size: 3.125vw;
    font-weight: bold;
    color: #182987;
    border-radius: 5.208vw;
    background-color: #fff;
    margin-top: 10.417vw;
}
.m_upload_icon {
    width: 2.083vw;
    height: 2.083vw;
    border-top: 0.313vw solid #182987;
    border-right: 0.313vw solid #182987;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    margin-left: 2.083vw;
}
.m_nav {
    width: 100%;
    position: fixed;
    top: 13.854vw;
    left: 0;
    background-color: #fff;
    z-index: 99999;
}
.m_nav_item {
    width: 100%;
    height: 10.417vw;
    line-height: 10.417vw;
    color: #9D9D9D;
    background-color: #222222;
    padding-left: 6.25vw;
}
.m_nav_item_active {
    width: 100%;
    height: 10.417vw;
    line-height: 10.417vw;
    color: #fff;
    background-color: #000;
    padding-left: 6.25vw;
}
.content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5.208vw 0;
    background-image: url(/static/media/m_image_bg.08edb8f4.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.content_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4.167vw 3.125vw;
    margin: 10.417vw 4.167vw;
    background-color: #27478561;
    color: #fff;
    border-radius: 2.083vw;
}
.upload_img {
    width: 31.25vw;
    height: 31.25vw;
    margin-top: 4.167vw;
}
.footer {
    width: 90vw !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #23211F;
    color: #fff;
    padding: 4.167vw 0;
    margin-top: 4.167vw;
    border-radius: 1.042vw;
    margin:  0 auto;
}
.about_user {
    font-size: 4.167vw;
}
.line {
    width: 80%;
    height: 0.104vw;
    background-color: #ccc;
    margin: 4.167vw 0;
}
.icon_list {
    display: flex;
    align-items: center;
}
.icon_list_img {
    width: 10.417vw;
    height: 10.417vw;
    margin-right: 4.167vw;
}
.icon_list_img:nth-last-child(1) {
    width: 10.417vw;
    height: 10.417vw;
    margin-right: 0;
}
.footer_nav {
    display: flex;
    align-items: center;
}
.footer_nav_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 3.646vw;
    margin-right: 3.125vw;
}
.footer_des {
    text-align: center;
    font-size: 1.25vw;
    padding: 0 5.208vw;
}
.m_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3.75vw 5.833vw;
    background-color: #fff;
}
.m_logo {
    width: 27.5vw;
    height: 5.208vw;
}
.m_nav_icon {
    width: 6.25vw;
    height: 4.167vw;
}
.m_main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 26.042vw 0;
    height: calc(100vh - 13.854vw);
    background-image: url(/static/media/m_image_bg.08edb8f4.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.m_mc_tit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 5.208vw;
    font-weight: bold;
    line-height: 1.6em;
}
.m_upload {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.m_upload_logo {
    width: 20.833vw;
    height: 20.833vw;
    border-radius: 3.49vw;
}
.m_upload_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 41.667vw;
    height: 10.417vw;
    line-height: 10.417vw;
    text-align: center;
    font-size: 3.125vw;
    font-weight: bold;
    color: #182987;
    border-radius: 5.208vw;
    background-color: #fff;
    margin-top: 10.417vw;
}
.m_upload_icon {
    width: 2.083vw;
    height: 2.083vw;
    border-top: 0.313vw solid #182987;
    border-right: 0.313vw solid #182987;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    margin-left: 2.083vw;
}
.m_nav {
    width: 100%;
    position: fixed;
    top: 13.854vw;
    left: 0;
    background-color: #fff;
    z-index: 99999;
}
.m_nav_item {
    width: 100%;
    height: 10.417vw;
    line-height: 10.417vw;
    color: #9D9D9D;
    background-color: #222222;
    padding-left: 6.25vw;
}
.m_nav_item_active {
    width: 100%;
    height: 10.417vw;
    line-height: 10.417vw;
    color: #fff;
    background-color: #000;
    padding-left: 6.25vw;
}
.content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5.208vw 0;
    min-height: calc(100vh - 13.854vw);
    background-image: url(/static/media/m_image_bg.08edb8f4.png);
    background-repeat: no-repeat;
    background-size: cover;
    overflow-x: hidden;
}
.content_box {
    padding: 4.167vw 3.125vw;
    margin: 0 4.167vw;
    background-color: #27478561;
    color: #ccc;
    border-radius: 2.083vw;
}
.caption {
    font-size: 4.167vw;
    font-weight: bold;
    margin-bottom: 2.083vw;
}
.content_img {
    width: 100%;
    margin-top: 2.083vw;
}
.bold_text {
    color: #fff;
    font-weight: bold;
    margin: 2.083vw 0;
}
.footer {
    width: 90vw !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #23211F;
    color: #fff;
    padding: 4.167vw 0;
    margin-top: 4.167vw;
    border-radius: 1.042vw;
    margin:  0 auto;
}
.about_user {
    font-size: 4.167vw;
}
.line {
    width: 80%;
    height: 0.104vw;
    background-color: #ccc;
    margin: 4.167vw 0;
}
.icon_list {
    display: flex;
    align-items: center;
}
.icon_list_img {
    width: 10.417vw;
    height: 10.417vw;
    margin-right: 4.167vw;
}
.icon_list_img:nth-last-child(1) {
    width: 10.417vw;
    height: 10.417vw;
    margin-right: 0;
}
.footer_nav {
    display: flex;
    align-items: center;
}
.footer_nav_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 3.125vw;
}
.footer_des {
    text-align: center;
    font-size: 1.25vw;
    padding: 0 5.208vw;
}
